const Boxes = {
  box1: function () {
               var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 10, 10, -10],keepaspectratio: true, axis:true, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
			   brd1.create('text', [-6, 9.5, '<b> Parallelogram Rule for Addition of Two Vectors <b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
			   var resize = function () {
				brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
				brd1.fullUpdate();
					};
				window.onresize = resize;
				brd1.create('text', [9, 0.35, '<i>x&#770;</i>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
			    brd1.create('text', [0.25, 8,  '<i>y&#770;</i>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
			    var j =0;
				var moveC =brd1.create('point', [0, 0],{name:'<b>C</b>', fillOpacity:0.25, face:'circle',size:4, strokeColor:'black', fillColor:'yellow', label:{visible:function(){if(j==0){return false}else{return true}}, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
				var PtA =brd1.create('point', [0, 0],{name:'<b>O</b>', face:'circle',size:4, strokeColor:'black', fillColor:'yellow', fixed:true,label:{offset:[0, -15], CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
				var PtB =brd1.create('point', [-4, 4],{name:'<b>A</b> (Drag me!)', snapToGrid:true, face:'circle',size:4, strokeColor:'black', fillColor:'yellow', fixed:false, fillOpacity:0.5,label:{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
				var moveA= brd1.create('point', [0,0],{name:'', fillOpacity:0.25, face:'circle',size:4, strokeColor:'black', fillColor:'yellow'});
				var moveB= brd1.create('point', [0,0],{name:'', fillOpacity:0.25, face:'circle',size:4, strokeColor:'black', fillColor:'yellow'});
				var PtD =brd1.create('point', [3, 2],{name:'<b>B</b> (Drag me!)',snapToGrid:true, face:'circle',size:4, strokeColor:'black', fillColor:'yellow', fillOpacity:0.5, label:{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
				brd1.create('text', [function(){return PtB.X()*0.5-0.5*Math.sign(PtB.X());}, function(){return PtB.Y()*0.5+0.5;}, '<b>r_1</b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}});
				brd1.create('text', [function(){return PtD.X()*0.5-0.5*Math.sign(PtD.X());}, function(){return PtD.Y()*0.5+0.5;}, '<b>r_2</b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}});
				brd1.create('segment', [PtB, moveA], {strokeColor:'blue', dash:1});
				brd1.create('segment', [PtD, moveB], {strokeColor:'red', dash:1});
				brd1.create('arrow', [PtA, PtD], {visible:true, strokeColor:'blue', strokeWidth:3});
				brd1.create('arrow', [PtA, PtB], {visible:true, strokeColor:'red', strokeWidth:3});
				brd1.create('arrow', [PtA, moveC], {strokeColor:'black', strokeWidth:3});
				brd1.create('text', [-7, -4, function(){return 'OA = ['+ (PtB.X()).toFixed(2)+', ' + (PtB.Y()).toFixed(2) +']'}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
				var plus=brd1.create('image', ['/assets/addition.svg', [-5, -5.5],[0.8, 0.8]], {opacity:1, fixed:true});
				brd1.create('text', [-7, -6, function(){return 'OB = ['+ (-PtA.X()+PtD.X()).toFixed(2) + ', ' + (-PtA.Y()+PtD.Y()).toFixed(2) +']'}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
				brd1.create('text', [-7, -8, function(){return ' OC = ['+ (-PtA.X()+PtD.X()+PtB.X()).toFixed(2) + ', ' + (-PtA.Y()+PtD.Y()+PtB.Y()).toFixed(2) +']'}],{visible:function(){if(j==0){return false}else{return true}},CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
				brd1.create('segment',[[-8, -7],[-2, -7]],{fixed:true, strokeColor:'black', strokeWidth:3});
				var runb =function(){moveB.moveTo([PtB.X()+PtD.X(), PtB.Y()+PtD.Y()], 500)}
				var runc =function(){moveA.moveTo([PtB.X()+PtD.X(), PtB.Y()+PtD.Y()], 500)}
				var runa =function(){moveC.moveTo([PtB.X()+PtD.X(), PtB.Y()+PtD.Y()], 2000)}
				plus.on('down', function(){j=1; moveA.moveTo([PtB.X(), PtB.Y()]); moveB.moveTo([PtD.X(), PtD.Y()]); moveC.moveTo([0,0]); runb();runc();runa();});
				PtB.on('drag',function(){j=0; moveC.moveTo([0,0]); moveA.moveTo([0,0]); moveB.moveTo([0,0])});
			    PtD.on('drag',function(){j=0; moveC.moveTo([0,0]); moveA.moveTo([0,0]); moveB.moveTo([0,0])});
    },
}
export default Boxes;
