<template>
  <div>
    <h3 ref="ia">
      Addition of Two Vectors
    </h3>
    <p>
      There are scenarios where we need to add two or more vectors.
      Let us give you an example. Consider the game of soccer with two players &mdash; Ally and Alan.

      Let's say Ally kicks the ball towards Alan, and Alan kicks the ball to its final location.
      Suppose the location of Alan relative to Ally is given by vector \(\mathbf r_1 \), and the location of the ball relative to Alan
      is given by vector \(\mathbf r_2\). Then, the location of the ball relative to Ally can be determined as the
      sum of \(\mathbf r_1 \) and \(\mathbf r_2 \).
      $$\mathbf r = \mathbf r_1 + \mathbf r_2$$
    </p>
    <h3 ref="meth">
      Methods of Vector Addition
    </h3>
    <p> There are two ways to add two or more vectors.</p>
    <h5> Direct Method </h5>
    <p>
      First, we express each vector in its component form.
      Then, the sum of the two vectors can be obtained by adding the respective components of the two vectors.
      Below is an example.
      Let's say the vector \( \mathbf r_1 \) in component form is given as
      $$r_1 = a_1 \hat{x} + b_1 \hat{y}$$
      Let's say the vector \( \mathbf r_2 \) in component form is given as
      $$r_2 = a_2 \hat{x} + b_2 \hat{y}$$
      Then the sum of two vectors can be obtained as
      $$\mathbf r = \mathbf r_1 + \mathbf r_2
      = (a_1+a_2) \hat x + (b_1+b_2) \hat y $$
    </p>
    <h5> Parallelogram Rule </h5>
    <p>
      Parallelogram rule is a graphical method used for the addition of two vectors. The rule can be described in two steps.
    </p><h5> Step 1 </h5>
    <p> Draw a parallelogram with the two vectors as the adjacent sides. </p>
    <br>
    <v-layout justify-center>
      <v-img contain
             src="/assets/addition0.png"
             max-height="650px"
             max-width="650px"
      />
    </v-layout>
    <br>
    <h5> Step 2 </h5>
    <p>
      Draw the diagonal of the parallelogram through the point common to both the vectors.
      That diagonal represents the sum of the two vectors.
    </p>
    </p>
    <br>
    <v-layout justify-center>
      <v-img contain
             src="/assets/addition.png"
             max-height="650px"
             max-width="650px"
      />
    </v-layout>
    <br>
    <h3 ref="pg">
      MagicGraph | Parallelogram Rule for Vector Addition
    </h3>
    <p> In this MagicGraph, You will learn about parallelogram rule for vector addition. Follow the steps below to get started. </p>
    <ul style="list-style-type: square;">
      <li><h5>Step 1</h5> Drag points A and B to adjust the vectors \(\mathbf r_1\) and \(\mathbf r_2\)  </li> <br>
      <li>
        <h5>Step 2</h5>
        Tap on the + sign to calculate. MagicGraph automatically draws the parallelogram and the diagonal through the point common to both the vectors, and finds
        the sum of the two vectors.
      </li> <br>
    </ul>
    <p> You can repeat the above steps any number of times to learn different scenarios. </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Resistors',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Addition of Two Vectors';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Sum of Two Vectors', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Methods of Vector Addition', img:'/assets/number-2.svg', action: () => this.goto('meth')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Vector Addition',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
